<template>
  <div class="pt-authors">
    <span>{{ title }}</span>
    <ul class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <li v-for="(item, index) in authors" :key="index">
        <figure class="pt-authors__item">
          <NuxtImg
            v-if="item.image && item.image.url"
            :key="item.image.url"
            class="pt-authors__image"
            :title="item.image.alternativeText || item.name"
            :src="item.image.url"
            :alt="item.image.alternativeText ? item.image.alternativeText : 'autor'"
            loading="lazy"
          />
          <div v-else>
            <icon name="pt-user" class="pt-authors__image pt-authors__image--empty" />
          </div>
          <figcaption class="pt-authors__profile">
            <component
              :is="item.vip ? NuxtLink : 'div'"
              :to="`/${section}/` + item.slug"
              class="pt-authors-event__authorLink"
              :class="{ 'hover:text-blue ': item.vip }"
            >
              <span style="font-weight: bold">{{ item.name }}</span>
            </component>
            <span>{{ item.job }}</span>
          </figcaption>
        </figure>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Authors',
  props: {
    list: {
      type: [Array, Object],
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    section: {
      type: String,
      default: 'autor'
    }
  },
  setup() {
    return { NuxtLink: resolveComponent('NuxtLink') };
  },
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    authors() {
      if (Array.isArray(this.list)) {
        return this.list;
      } else if (typeof this.list === 'object' && this.list !== null) {
        return [this.list];
      } else {
        return [];
      }
    }
  }
};
</script>
<style src="./style.scss" lang="scss" />
