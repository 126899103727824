<template>
  <div>
    <client-only>
      <vueper-slides
        ref="vueperslides1"
        :touchable="false"
        fade
        :arrows="false"
        :bullets="false"
        :fixed-height="lg ? '400px' : '200px'"
        @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
      >
        <vueper-slide v-for="(slide, i) in images" :key="i" :image="slide.url" />
      </vueper-slides>

      <vueper-slides
        ref="vueperslides2"
        class="slide-bottom no-shadow"
        :fixed-height="lg ? '100px' : '70px'"
        :visible-slides="4"
        :dragging-distance="50"
        :gap="1.5"
        :arrows="lg"
        :bullets="false"
        :touchable="false"
        @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
      >
        <vueper-slide
          v-for="(slide, i) in images"
          :key="i"
          :image="slide.url"
          style="background-size: contain; background-repeat: no-repeat"
          @click="$refs.vueperslides2.goToSlide(i)"
        />
      </vueper-slides>
    </client-only>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import Breakpoints from '~/mixins/Breakpoints';

export default {
  name: 'PtGallery',
  components: { VueperSlides, VueperSlide },
  mixins: [Breakpoints],
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const images2 = props.images;
    return {
      images2
    };
  }
};
</script>
<style src="./style.scss" lang="scss" />
