<template>
  <div class="pt-download-book">
    <div class="pt-download-book__wrapper">
      <NuxtImg
        :key="image.url"
        class="hidden lg:block"
        :src="image.url"
        :alt="image.alternativeText || 'book to download'"
        :title="image.alternativeText"
      />
    </div>
    <p class="hidden lg:block mt-6">{{ text }}</p>
    <button
      v-if="getAuth"
      id="btn-register-book"
      class="btn justify-center w-full h-12 border mt-6 my-8 text-blue border-blue"
      type="button"
      @click.stop.prevent="downloadPdf"
    >
      <icon v-if="loading" name="spinner" class="animate-spin mr-2" />
      <template v-else>
        <span> {{ $t('ui.button.download') }} </span>
        <icon name="pt-download-alt" class="text-xl ml-1" />
      </template>
    </button>
    <div v-else>
      <button
        class="btn justify-center w-full h-12 border mt-6 mb-4 text-orange border-orange-dark"
        type="button"
        @click="goToLogin"
      >
        <span> {{ $t('ui.button.download') }} </span>
        <icon name="pt-download-alt" class="text-xl ml-1" />
      </button>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="no-auth" v-html="$t('book.download.helpText')" />
      <p class="no-auth mt-1">
        {{ $t('book.download.registerText') }}
        <NuxtLink
          :to="`${REGISTER_BASE_URL}?trigger=book&redirect=${$route.fullPath}`"
          class="text-orange"
        >
          {{ $t('book.download.registerLink') }}
        </NuxtLink>
      </p>
    </div>
    <client-only>
      <modal-wrapper name="errorFile" width="700">
        <Confirm :message="errorMessage" />
      </modal-wrapper>
    </client-only>
  </div>
</template>

<script>
import { REGISTER_BASE_URL } from '@/utils/enums';
import { downloadFileCrossBrowser } from '@/utils/helpers/script';
import ModalWrapper from '@/components/ModalWrapper';
import Confirm from '@/components/Profile/Confirm';
export default {
  name: 'PtDownloadBook',
  components: {
    ModalWrapper,
    Confirm
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => ({})
    },
    filename: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { $modal, $msal, $t } = useNuxtApp();
    const triggerAfterRegister = useCookie('triggerAfterRegister');
    const state = reactive({
      errorMessage: {},
      loading: false
    });
    const { errorMessage, loading } = toRefs(state);

    const mountedTriggerDownload = () =>
      triggerAfterRegister.value === 'book' && !loading.value && getAuth.value
        ? downloadPdf()
        : triggerDownload();

    const triggerDownload = () => {
      if (triggerAfterRegister.value && !loading.value && getAuth.value) {
        afterCreateProfile(triggerAfterRegister.value);
      }
      triggerAfterRegister.value = null;
    };
    const afterCreateProfile = type => {
      if (type === 'book') $modal.open('download-book');
    };
    const downloadPdf = async () => {
      try {
        loading.value = true;
        const uri = await store.dispatch('profile/fileDownload', {
          pathParam: 'getPublicationFile',
          slug: props.filename
        });
        await store.dispatch('profile/checkFileExist', {
          uri
        });
        downloadFileCrossBrowser(uri);
      } catch (err) {
        console.error(err);
        $modal.open('errorFile');
      } finally {
        loading.value = false;
      }
    };
    const goToLogin = () => {
      triggerAfterRegister.value = 'book';
      $msal.signIn(route.path);
    };

    const baseUrl = computed(() => process.env.APP_BASE_URL_DOWNLOAD_PDF);
    const getAuth = computed(() => $msal.getIsAuthenticated());

    onMounted(async () => {
      errorMessage.value = {
        text: $t('message.error.errorFile'),
        type: 'error'
      };
      await mountedTriggerDownload();
      triggerAfterRegister.value = null;
    });

    return {
      baseUrl,
      loading,
      getAuth,
      goToLogin,
      downloadPdf,
      errorMessage,
      triggerDownload,
      REGISTER_BASE_URL,
      triggerAfterRegister
    };
  }
};
</script>
<style src="./style.scss" lang="scss" />
